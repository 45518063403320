import { createRouter, createWebHistory } from 'vue-router'

const titleAll = "雲son黄金"
const domain = "https://futures.yunsonbai.top"
const types = {
  'gd': '人民币黄金', 'si': '人民币白银', 'pl': '人民币铂金', 'pa': '人民币钯金',
  'nygd': '纽约黄金', 'nysi': '纽约白银', 'nypl': '纽约铂金', 'nypa': '纽约钯金',
  'rmbdol': '美元兑人民币', 'rmbeu': '欧元兑人民币'}
const ttypes = {'day': '日线价格走势', 'week': '周线价格走势', 'month': '月线价格走势'}
const date = new Date();
const month = date.getMonth() + 1

const routes = [
  {
    path: '/',
    name: 'IndexFutures',
    component: () => import('../views/index.vue'),
    meta:{
      title: '行情',
      keywords: '人民币黄金行情,纽约金行情,铂金行情,人民币汇率,白银行情,贵金属行情',
      description: '提供黄金|白银|铂金|钯金最新价格、最新涨幅和历史价格,包括人民币黄金、人民币白银、纽约黄金和纽约铂金等,同时提供人民币兑美元和人民币兑欧元最新汇率'
    }
  },
  {
    path: '/price/:type/:ttype.html',
    name: 'Futures',
    component: () => import('../views/futures.vue'),
    meta:{}
  },
  {
    path: '/news.html',
    name: 'News',
    component: () => import('../views/news.vue'),
    meta:{
      title: '资讯',
      keywords: '期货最新资讯,期货资讯,人民币黄金行情,纽约金行情,铂金行情',
      description: '提供黄金|白银|铂金|钯金最新价格、最新涨幅和历史价格,包括人民币黄金、人民币白银、纽约黄金和纽约铂金等,同时提供人民币兑美元和人民币兑欧元最新汇率'
    }
  },
  {
    path: '/etf/:type.html',
    name: 'Etf',
    component: () => import('../views/etf.vue'),
    meta:{
      title: 'ETF',
      keywords: '黄金ETF持仓,SPDR Gold Trust,白银ETF持仓,iShares Silver Trust,ETF历史持仓',
      description: '实时追踪黄金ETF(SPDR Gold Trust)和白银ETF(iShares Silver Trust)两大机构的持仓变化,并提供历史数据曲线图,方便作为购买黄金白银的参考'
    }
  },
  {
    path: '/feinong.html',
    name: 'Feinong',
    component: () => import('../views/feinong.vue'),
    meta:{
      title: '非农',
      keywords: month +'月美国非农数据,' + '最新美国非农数据,美国非农历史数据',
      description: '提供最新美国非农数据和历史非农数据, 非农预测数据, 并标注非农数据对贵金属的价格走势影响,提供投资参考'
    }
  },
  {
    path: '/cpi/:type.html',
    name: 'Cpi',
    component: () => import('../views/cpi.vue'),
    meta:{
      title: 'CPI',
      keywords: '美国cpi数据,美国消费者物价指数,美国cpi历史数据,cpi年率,cpi核心年率',
      description: '实时追踪美国公布的cpi数据, 美国消费者物价指数直接反应美国当前的通胀水平, 进而影响大宗商品价格,包括黄金白银, 本站提供美国cpi年率和cpi核心年率, 并提供cpi历史数据, 在购买期货产品时提供一些经济数据参考'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  var tmpTitle = ''
  var desc = ''
  var keywords = ''
  if (to.fullPath.includes('/price/')) {
    tmpTitle = types[to.params.type] + ttypes[to.params.ttype]
    desc = '提供' + types[to.params.type] + ttypes[to.params.ttype] + '最新价格和历史价格K线, 拖动鼠标或者手指即可浏览开盘、收盘、最低和最高价格'
    keywords = types[to.params.type] + '日价格K线图,' +types[to.params.type]+ '周价格K线图,' + types[to.params.type] + '月价格K线图'
  }else{
    tmpTitle = to.meta.title
    desc = to.meta.description
    keywords = to.meta.keywords
  }
  tmpTitle = tmpTitle + '|' +titleAll
  document.title = tmpTitle

  document.querySelector('meta[name="keywords"]').setAttribute('content', keywords);
  document.querySelector('meta[name="description"]').setAttribute('content', desc);
  document.querySelector('meta[property="og:type"]').setAttribute('content', "news");
  document.querySelector('meta[property="og:title"]').setAttribute('content', tmpTitle);
  document.querySelector('meta[property="og:site_name"]').setAttribute('content', titleAll);
  document.querySelector('meta[property="og:description"]').setAttribute('content', desc);
  document.querySelector('meta[property="og:url"]').setAttribute('content', domain + to.fullPath);
  next()
})

export default router
