<template>
    <div id="nav">
    <div class="trademark-container">
      <a class="trademark-title" href="/">雲son黄金</a>
    </div>
    <router-link to="/" class="router">行情</router-link>
    <router-link to="/news.html" class="router">资讯</router-link>
    <router-link to="/etf/gd.html" :class="navIndex==2?routerActive:noRouterActive" class="router" >ETF</router-link>
    <router-link to="/feinong.html" class="router">非农</router-link>
    <router-link to="/cpi/year.html" :class="navIndex==4?routerActive:noRouterActive" class="router">CPI</router-link>
  </div>
</template>

<script>
export default {
  name: 'Nav',
  props: ['navIndex'],
  data() {
    return {
      routerActive: "router-link-active router-link-exact-active",
      noRouterActive: ""
    }
  },
}
</script>
<style>
  @import '../css/main.css';
</style>